/*
 * CookieManager default styles
 */

.debug-stats {
  font-size: 80%;
  font-weight: bold;
  border: 1px dashed #cecece;
  padding: 10px;

  span {
    font-weight: normal;
    text-transform: uppercase;
    display: flex;
  }
}

.cookie-manager {
  color: #222;

  .text a {
    color: #222;
    text-decoration: underline;
  }
}

.settings-view {
  h4 {
    font-size: 28px;
  }
}

.modal-body {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-track {
    background-color: #fff;
    border-radius: 8px;
  }
}

.modal-footer {
  padding-top: 10px;
  .button {
    border: 0;
    margin-bottom: 10px;
    background-color: #eee;
    padding: 7px 15px;

    @media screen and (min-width: 768px) {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }
}

.button.save-cookie-settings {
  margin-bottom: 0;
}
.button.edit-cookie-settings {
  background-color: transparent;
  color: #222;
  border: 1px solid #222;
}
.button.accept-cookie-settings,
.button.save-cookie-settings {
  background-color: #5cc230;
  color: #fff;

  &:hover {
    background-color: darken(#5cc230, 5%);
  }
}

.button.edit-cookie-settings,
.button.accept-cookie-settings,
.button.save-cookie-settings,
.button.delete-cookies {
  // font-weight: bold;
  // font-size: 18px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    text-decoration: underline;
  }
}

.help-text {
  h3 {
    font-size: 28px;
  }
  p {
    font-size: 18px;
    line-height: 1.3;
  }
}
