/*
 * Modal default styles
 */

// modal
.modal-mask {
  background-color: rgba(#000, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  // padding-bottom: 10vh;
  z-index: 1000;
}
.modal-wrapper {
  background-color: #fff;
  max-width: 90vw;
  pointer-events: all;
  top: -10vh;

  @media screen and (min-width: 768px) {
    max-width: 1080px;
    width: 90vw;
  }
}
.modal-body {
  max-height: 60vh;
  overflow-y: auto;
  position: relative;

  @media screen and (min-width: 768px) {
    max-height: 80vh;
  }
}
.modal-footer {
  display: flex;
  justify-content: flex-end;
  flex-direction: column-reverse;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
}

// transitions
.slide-fade-enter-active {
  transition: all 0.5s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: scale(1.1);
  opacity: 0;
}
